import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { Icon } from '@iconify/react';
// layouts
const DashboardLayout = React.lazy(() => import('./layouts/dashboard'));
const LogoOnlyLayout = React.lazy(() => import('./layouts/LogoOnlyLayout'));
// lazy components
const Login = React.lazy(() => import('./pages/Login'));
const DashboardApp = React.lazy(() => import('./pages/DashboardApp'));
const Agents = React.lazy(() => import('./pages/Agents'));
const NotFound = React.lazy(() => import('./pages/Page404'));
const PageNewUser = React.lazy(() => import('./pages/PageNewUserRequest'));

// ----------------------------------------------------------------------

const LoadingComponent = () => (
  <div
    style={{
      position: 'fixed',
      background: 'white',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'grid',
      placeItems: 'center'
    }}
  >
    <Icon icon="eos-icons:loading" color="#19bb62" width="100" height="100" />
  </div>
);

let isToken = false;
if (
  window.localStorage.getItem('accessToken') &&
  window.localStorage.getItem('accessToken').length !== 0
) {
  isToken = true;
}

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: isToken ? (
        <React.Suspense fallback={<LoadingComponent />}>
          <DashboardLayout />
        </React.Suspense>
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        { element: <Navigate to="/dashboard/agents" replace /> },
        {
          path: 'app',
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <DashboardApp />
            </React.Suspense>
          )
        },
        {
          path: 'agents',
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Agents />
            </React.Suspense>
          )
        }
      ]
    },
    {
      path: '/',
      element: (
        <React.Suspense fallback={<LoadingComponent />}>
          <LogoOnlyLayout />
        </React.Suspense>
      ),
      children: [
        {
          path: 'login',
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Login />
            </React.Suspense>
          )
        },
        {
          path: '404',
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <NotFound />
            </React.Suspense>
          )
        },
        {
          path: 'request-more-users',
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <PageNewUser />
            </React.Suspense>
          )
        },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
