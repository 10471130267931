import { createStore } from 'redux';

const tokenFromLocalStorage = window.localStorage.getItem('accessToken') || '';

const initialState = {
  token: tokenFromLocalStorage,
  isAuth: false,
  user: null,
  agentId: '',
  allAgents: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'AUTH': {
      window.localStorage.setItem('accessToken', action.payload.token);
      return { ...state, isAuth: true, token: action.payload.token, user: action.payload.user };
    }
    case 'SET_AGENT_ID': {
      return { ...state, agentId: action.payload };
    }
    case 'SET_ALL_AGENTS': {
      return { ...state, allAgents: action.payload };
    }

    default:
      return state;
  }
};

const store = createStore(reducer);

export default store;
